<template>
  <v-card>
    <v-card-text>
      <v-form ref="form">
        <v-container>
          <v-row dense>
            <v-col
              v-if="isAuthorized('report', 'createStatisticHistory')"
              cols="12"
              md="4"
              sm="5"
              lg="3"
              xl="2"
            >
              <v-checkbox
                v-model="createStatisticHistory"
                label="Tallenna statistiikkahistoria"
                dense
                outlined
                hide-details
              ></v-checkbox>

              <small>Tiedot tallennetaan kerran viikossa.</small>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col
              v-if="isAuthorized('report', 'regionReports')"
              cols="12"
              md="4"
              sm="5"
              lg="3"
              xl="2"
            >
              <v-checkbox
                v-model="regionReports"
                label="Aluekohtainen statistiikka"
                dense
                outlined
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import mixins from "../../mixins/mixins";

export default {
  mixins: [mixins],

  computed: {
    ...mapState("settings", ["account"]),

    regionReports: {
      get() {
        return this.account?.settings?.regionReports || false;
      },
      set(val) {
        this.setFormField({ field: "settings.regionReports", val });
      },
    },

    createStatisticHistory: {
      get() {
        return this.account?.settings?.createStatisticHistory || false;
      },
      set(val) {
        this.setFormField({ field: "settings.createStatisticHistory", val });
      },
    },
  },

  methods: {
    ...mapMutations("settings", ["setFormField"]),
  },
};
</script>

<style></style>
