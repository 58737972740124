<template>
  <v-card>
    <v-card-title>{{ title }}</v-card-title>
    <v-card-text>
      <v-container>
        <v-row dense>
          <v-col cols="12" md="6">
            <h3>{{ text }}</h3>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import invoiceMixins from "@/mixins/invoiceMixins";

export default {
  mixins: [invoiceMixins],

  computed: {
    ...mapState("settings", ["account"]),

    ropoCid() {
      return this.account?.settings?.invoice?.ropoCapital?.cid || "määrittelemättä.";
    },

    talenomOrganizationId() {
      return this.account?.settings?.invoice?.talenom?.organizationNumber || "määrittelemättä.";
    },

    title() {
      if (this.currentInvoiceService === "ropoCapital") {
        return `Ropo Oy`;
      } else if (this.currentInvoiceService === "talenom") {
        return `Talenom Online`;
      }
      return "Laskutusintegraatiota ei ole määritelty";
    },

    text() {
      if (this.currentInvoiceService === "ropoCapital") {
        return `Ropo One asiakasnumerosi on ${this.ropoCid}`;
      } else if (this.currentInvoiceService === "talenom") {
        return `Talenom Online organisaationumerosi on ${this.talenomOrganizationId}`;
      }
      return "Asiakastunnusta ei ole määritelty";
    },
  },
};
</script>

<style></style>
