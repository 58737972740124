<template>
  <v-card>
    <v-card-text>
      <v-container>
        <h3 class="mb-1">Sähköpostien lähetysasetukset</h3>
        <v-row dense>
          <v-col cols="12" sm="6" md="4" xl="2">
            <label>Sopimuksien lähetys aktivoinnin yhteydessä</label>
            <v-radio-group
              v-model="sendContractSignEmail"
              :mandatory="true"
              style="margin-top: 0px"
              hide-details
            >
              <v-radio label="Kyllä" :value="true"></v-radio>
              <v-radio label="Ei" :value="false"></v-radio>
            </v-radio-group>
            <div v-if="sendContractSignEmail" style="margin-top: 10px; max-width: 700px">
              <small style="font-size: 11px; color: #959595">
                Sähköisesti allekirjoitetut sopimukset eivät lähde automaattisesti sopimuksen
                aktivoinnin yhteydessä vaan ne toimitetaan allekirjoitusprosessin päättyessä.
                Manuaalisesti allekirjoitetut sopimukset toimitetaan vuokraisille, vuokranantajille
                ja alla määritetyille vastaanottajille automaattisesti, jos valitset kyllä.
              </small>
            </div>
          </v-col>

          <v-col cols="12" sm="6" md="4" xl="2">
            <label>Irtisanomisilmoituksen lähetys</label>
            <v-radio-group
              v-model="sendContractRevokeEmail"
              :mandatory="true"
              style="margin-top: 0px"
              hide-details
            >
              <v-radio label="Kyllä" :value="true"></v-radio>
              <v-radio label="Ei" :value="false"></v-radio>
            </v-radio-group>
            <div v-if="sendContractRevokeEmail" style="margin-top: 10px; max-width: 700px">
              <small style="font-size: 11px; color: #959595">
                Irtosanomisilmoitus toimitetaan vuokraisille, vuokranantajille ja alla määritetyille
                vastaanottajille automaattisesti, jos valitset kyllä.
              </small>
            </div>
          </v-col>

          <v-col cols="12" sm="6" md="4" xl="2">
            <label>Vuokrankorotusilmoitusten lähetys</label>
            <v-radio-group
              v-model="sendRentIncreaseEmail"
              :mandatory="true"
              style="margin-top: 0px"
              hide-details
            >
              <v-radio label="Kyllä" :value="true"></v-radio>
              <v-radio label="Ei" :value="false"></v-radio>
            </v-radio-group>
            <small style="font-size: 11px; color: #959595">
              Raportti lähetetään 2 kk ennen vuokrankorotuspäivää valituille vastaanottajille.
            </small>
          </v-col>

          <v-col cols="12" sm="6" md="4" xl="2">
            <label>Kuluvan kuun maksusuoritukset</label>
            <v-radio-group
              v-model="monthlyReceivedPaymentsEmail"
              :mandatory="true"
              style="margin-top: 0px"
              hide-details
            >
              <v-radio label="Kyllä" :value="true"></v-radio>
              <v-radio label="Ei" :value="false"></v-radio>
            </v-radio-group>
            <small style="font-size: 11px; color: #959595">
              Raportti lähetetään kerran viikossa valituille vastaanottajille.
            </small>
          </v-col>

          <v-col cols="12" sm="6" md="4" xl="2">
            <label>Laskulähetysten virheilmoitukset</label>
            <v-radio-group
              v-model="invoiceErrorEmail"
              :mandatory="true"
              style="margin-top: 0px"
              hide-details
            >
              <v-radio label="Kyllä" :value="true"></v-radio>
              <v-radio label="Ei" :value="false"></v-radio>
            </v-radio-group>
            <small style="font-size: 11px; color: #959595">
              Raportti lähetetään tarvittaessa valituille vastaanottajille.
            </small>
          </v-col>
          <v-col cols="12" sm="6" md="4" xl="2">
            <label>Vesitasauslaskun pyyntö</label>
            <v-radio-group
              v-model="waterEqualizationEmail"
              :mandatory="true"
              style="margin-top: 0px"
              hide-details
            >
              <v-radio label="Kyllä" :value="true"></v-radio>
              <v-radio label="Ei" :value="false"></v-radio>
            </v-radio-group>
            <small style="font-size: 11px; color: #959595">
              Lähetetään automaattisesti 14 päivää ennen irtisanomis- tai päättymispäivää.
              Irtisanomisen yhteydessä lähetetään myös viikko jälkikäteen tehtyihin irtisanomisiin.
              Pakolliset kentät: Taloyhtiö/Kiinteistö -> "Vesitasauksen laskupyynnön vastaanottajat"
              ja "Vesimaksun peruste: kulutuksen mukaan" sekä Tilin asetukset -> "Automaattisten
              sähköpostien allekirjoitukset"
            </small>
          </v-col>
          <v-col cols="12" md="4" sm="5">
            <label>Vakuuksien muistutusviesti</label>
            <v-radio-group
              v-model="sendDepositReminderEmails"
              :mandatory="true"
              style="margin-top: 0px"
              hide-details
            >
              <v-radio label="Kyllä" :value="true"></v-radio>
              <v-radio label="Ei" :value="false"></v-radio>
            </v-radio-group>
            <small style="font-size: 11px; color: #959595">
              Automaattinen muistutusviesti joka kuun 15. päivä vakuuksista, joiden tavoite ei ole
              täyttynyt tai samasta vuokrakohteesta löytyy päällekkäisiä vakuuksia.
            </small>
          </v-col>
        </v-row>

        <div>
          <v-form ref="form">
            <v-divider class="mt-2"></v-divider>

            <h3 class="mb-1 mt-2">Automaattisten sähköpostien allekirjoitukset</h3>

            <v-row dense>
              <v-col cols="12" sm="6" md="4"
                ><v-select
                  v-model="signEmailsBy"
                  :items="accountUsers"
                  item-text="name"
                  item-value="_id"
                  dense
                  outlined
                ></v-select
              ></v-col>
            </v-row>

            <v-divider class="mt-2"></v-divider>

            <h3 class="mb-1 mt-2">Määrittele sähköpostien vastaanottajat</h3>
            <!-- Notifications -->

            <v-row dense>
              <v-col
                cols="12"
                sm="6"
                md="4"
                v-for="(item, idx) in account.settings.emailNotifications"
                :key="'a' + `${idx}`"
              >
                <p
                  style="cursor: pointer"
                  class="error--text mb-1"
                  @click="deleteEmailNotification(idx)"
                >
                  Poista
                </p>
                <v-text-field
                  class="mb-1"
                  :value="item.email"
                  label="Email"
                  :rules="required.concat(emailRules)"
                  dense
                  outlined
                  hide-details
                  @input="setEmailValue({ $event, idx })"
                ></v-text-field>
                <p>Viestit</p>
                <v-select
                  :value="item.resource"
                  :items="notificationTypesByAccount"
                  item-value="val"
                  item-text="text"
                  outlined
                  small-chips
                  deletable-chips
                  multiple
                  hide-details
                  :rules="requiredLength"
                  @input="setResourceValue({ $event, idx })"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>

          <v-row class="mt-1 mb-2" dense>
            <v-col cols="12" md="4" sm="5">
              <v-btn color="primary" @click="addEmailNotification">Lisää vastaanottaja</v-btn>
            </v-col>
          </v-row>

          <v-divider class="mt-1"></v-divider>

          <h3 class="mb-1 mt-2">Sähköpostipohjat</h3>
          <v-row dense>
            <v-col cols="12" sm="6" lg="5" xl="4">
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header
                    >Allekirjoitettujen sopimuksien vastaanottoviesti</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <small
                      v-html="
                        `Voit käyttää tägiä {{user}}, jolloin tägin kohdalle täytetään käyttäjän tiedot omasta profiilistasi.`
                      "
                      class="error--text"
                    >
                    </small>
                    <br />
                    <v-btn
                      class="mt-1"
                      small
                      color="primary"
                      @click="contractActivateMsg = defaultContractActivateMsg"
                      >Aseta esimerkkiteksti</v-btn
                    >

                    <v-textarea v-model="contractActivateMsg"></v-textarea>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
            <v-col cols="12" sm="6" lg="5" xl="4">
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header
                    >Irtisanomisviesti vuokranantajalle</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <small
                      v-html="
                        `Voit käyttää tägiä {{user}}, jolloin tägin kohdalle täytetään käyttäjän tiedot omasta profiilistasi. Tagi {{body}} on pakollinen.`
                      "
                      class="error--text"
                    >
                    </small>
                    <br />
                    <v-btn
                      class="mt-1"
                      small
                      color="primary"
                      @click="contractRevokeMsg = defaultContractRevokeMsg"
                      >Aseta esimerkkiteksti</v-btn
                    >

                    <v-textarea v-model="contractRevokeMsg"></v-textarea>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import mixins from "../../mixins/mixins";

export default {
  mixins: [mixins],

  data() {
    return {
      notificationTypes: [
        { text: "Sopimuksen aktivointi", val: "contractActivate" },
        { text: "Sopimuksen irtisanominen", val: "contractRevoke" },
        { text: "Yhteydenotot verkkosivuilta", val: "contactPublic" },
        { text: "Yhteydenotot verkkosivuilta jono", val: "contactQueuePublic" },
        { text: "Vuokrankorotukset", val: "rentIncrease" },
        { text: "Kuluvan kuun maksusuoritukset", val: "monthlyReceivedPayments" },
        { text: "Laskulähetysten virheilmoitukset", val: "invoiceErrors" },
        { text: "Vesitasauslaskun pyyntö", val: "waterEqualization" },
        { text: "Vakuuksien muistutusviesti", val: "depositReminder" },
      ],
      defaultContractActivateMsg:
        "<p>Hei,</p>\n<p>ohessa vuokrasopimus vuokraamaanne tilaan liittyen.</p>\n<p>Ystävällisin terveisin,</p>\n{{user}}",
      defaultContractRevokeMsg:
        "<p>Hei,</p>\n<p>Seuraava vuokrasopimus on irtisanottu:</p>\n{{body}}\n<p>Ystävällisin terveisin,</p>\n{{user}}",
      emailRules: [(v) => /.+@.+\..+/.test(v) || "Email ei ole oikeassa muodossa"],
      required: [(v) => !!v],
      requiredLength: [(v) => v.length > 0],
    };
  },

  created() {
    this.getAccountUsers(true);
  },

  computed: {
    ...mapState("settings", ["account"]),
    ...mapState("account", ["currentUser", "accountUsers"]),

    hasAccountsConnected() {
      return this.currentUser?.currentAccount?.accountsConnected > 0;
    },

    notificationTypesByAccount() {
      return this.notificationTypes.filter((el) => {
        if (this.hasAccountsConnected) {
          if (el.val !== "contactPublic" && el.val !== "contactQueuePublic") {
            return el;
          }
        } else {
          return el;
        }
      });
    },

    sendContractSignEmail: {
      get() {
        return this.account.settings.sendContractSignEmail;
      },
      set(val) {
        this.setFormField({ field: "settings.sendContractSignEmail", val });
      },
    },

    signEmailsBy: {
      get() {
        return this.account.settings.signEmailsBy;
      },
      set(val) {
        this.setFormField({ field: "settings.signEmailsBy", val });
      },
    },

    sendContractRevokeEmail: {
      get() {
        return this.account.settings.sendContractRevokeEmail;
      },
      set(val) {
        this.setFormField({ field: "settings.sendContractRevokeEmail", val });
      },
    },

    sendRentIncreaseEmail: {
      get() {
        return this.account.settings.sendRentIncreaseEmail;
      },
      set(val) {
        this.setFormField({ field: "settings.sendRentIncreaseEmail", val });
      },
    },

    monthlyReceivedPaymentsEmail: {
      get() {
        return this.account.settings.monthlyReceivedPaymentsEmail;
      },
      set(val) {
        this.setFormField({ field: "settings.monthlyReceivedPaymentsEmail", val });
      },
    },

    invoiceErrorEmail: {
      get() {
        return this.account.settings.invoiceErrorEmail;
      },
      set(val) {
        this.setFormField({ field: "settings.invoiceErrorEmail", val });
      },
    },

    waterEqualizationEmail: {
      get() {
        return this.account.settings.waterEqualizationEmail;
      },
      set(val) {
        this.setFormField({ field: "settings.waterEqualizationEmail", val });
      },
    },

    contractActivateMsg: {
      get() {
        return this.account.settings.contractActivateMsg;
      },
      set(val) {
        this.setFormField({ field: "settings.contractActivateMsg", val });
      },
    },
    contractRevokeMsg: {
      get() {
        return this.account.settings.contractRevokeMsg;
      },
      set(val) {
        this.setFormField({ field: "settings.contractRevokeMsg", val });
      },
    },
    sendDepositReminderEmails: {
      get() {
        return this.account.settings.sendDepositReminderEmails;
      },
      set(val) {
        this.setFormField({ field: "settings.sendDepositReminderEmails", val });
      },
    },
  },

  methods: {
    ...mapMutations("settings", [
      "setFormField",
      "deleteEmailNotification",
      "addEmailNotification",
      "setEmailValue",
      "setResourceValue",
    ]),
    ...mapActions("account", ["getAccountUsers"]),

    validate() {
      if (!this.$refs.form.validate()) {
        this.showPopup("Sähköpostiasetuksien pakolliset kentät ei ole täydennetty", "error");
      }

      return this.$refs.form.validate();
    },
  },
};
</script>

<style></style>
