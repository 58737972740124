<template>
  <v-card>
    <v-card-text>
      <v-container>
        <v-row dense align="center">
          <v-col cols="12" sm="4" md="3" lg="2" xl="1">
            <v-radio-group
              v-model="rentTerms"
              :mandatory="true"
              style="margin-top: 0px"
              hide-details
            >
              <v-radio label="AHVL" value="AHVL"></v-radio>
              <v-radio label="LHVL" value="LHVL"></v-radio>
            </v-radio-group>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3">
            <v-text-field
              v-model="defaultDueDateText"
              label="Oletusteksti vuokran eräpäiväksi"
              placeholder="Eräpäivä on kuukauden 5. päivä"
              outlined
              dense
              hide-details
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3">
            <v-select
              v-model="defaultDueDateNumber"
              outlined
              dense
              label="Oletuspäivä vuokran toistuvaksi eräpäiväksi"
              :items="globalValues.dueDates"
              item-text="text"
              item-value="date"
              hide-details
            ></v-select>
          </v-col>

          <v-col v-show="isAuthorized('image', 'contractPhoto')" cols="12" md="4" sm="5">
            <v-checkbox
              v-model="mandatoryPhotoOnContract"
              label="Pakota ottamaan valokuva vuokrakohteesta ennen vuokrasopimuksen hyväksymistä"
              hide-details
            ></v-checkbox>
          </v-col>

          <v-col cols="12" sm="5" md="4" lg="3">
            <v-checkbox
              v-model="confirmSignatures"
              label="Vaadi Vuokranetin allekirjoitukset ennen sopimuksen aktivoimista"
              hide-details
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import globalValues from "../../configs/globalValues";
import mixins from "../../mixins/mixins";

export default {
  mixins: [mixins],

  data() {
    return {
      globalValues,
    };
  },

  computed: {
    ...mapState("settings", ["account"]),

    defaultDueDateText: {
      get() {
        return this.account.settings.defaultDueDateText;
      },
      set(val) {
        this.setFormField({ field: "settings.defaultDueDateText", val });
      },
    },

    defaultDueDateNumber: {
      get() {
        return this.account.settings.defaultDueDateNumber;
      },
      set(val) {
        this.setFormField({ field: "settings.defaultDueDateNumber", val });
      },
    },
    rentTerms: {
      get() {
        return this.account.settings.rentTerms;
      },
      set(val) {
        this.setFormField({ field: "settings.rentTerms", val });
      },
    },
    mandatoryPhotoOnContract: {
      get() {
        return this.account.settings.mandatoryPhotoOnContract;
      },
      set(val) {
        this.setFormField({ field: "settings.mandatoryPhotoOnContract", val });
      },
    },
    confirmSignatures: {
      get() {
        return this.account.settings.confirmSignatures;
      },
      set(val) {
        this.setFormField({ field: "settings.confirmSignatures", val });
      },
    },
  },

  methods: {
    ...mapMutations("settings", ["setFormField"]),
  },
};
</script>

<style></style>
