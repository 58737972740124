<template>
  <v-card>
    <v-card-text>
      <v-container>
        <v-row dense>
          <!-- VISMA SIGN -->
          <v-col cols="12" sm="6" class="pr-sm-2">
            <h3 class="mb-1">Visma Sign</h3>
            <h4 v-if="vismaSignInUse" class="success--text">Valtuutus tehty</h4>

            <div v-else>
              <h4>Yritykset</h4>
              <p>
                Yritykset voivat käyttää omia Visma Sign-tilejään Vuokranetin valtuutuksella.
                Valtuutuspyynnöt tulevat näkyville Visma Sign-käyttöliittymän kohdassa "Asetukset ->
                Ohjelmistoyhteydet". Hyväksymällä valtuutuspyynnön ja ilmoittamalla siitä meille,
                voidaan ohjelmistoyhteys avata tiliisi.
              </p>

              <h4 class="mt-1">Yksityishenkilöt</h4>
              <p>
                Yksityishenkilöt voivat käyttää Vuokranetin Visma Sign allekirjoituksia vain
                Vuokranetin tarjoaman yhteistilin kautta. Yksityishenkilöt eivät pääse kirjautumaan
                Visma Signiin, vaan kaikki toimenpiteet hoidetaan Vuokranetin kautta.
              </p>

              <h4 class="mt-1">Visma Sign-rajapinnan avaaminen</h4>
              <p>
                Mikäli haluat ottaa Visma Signin käyttöösi Vuokranetissä, lähetä valtuutuspyyntö
                osoitteeseen jp.tammela@vuokranet.fi tai info@vuokranet.fi. Yritystilin käyttäjän
                tulee lähettää samalla Visma Sign-tilin y-tunnus valtuutuspyynnön ohessa.
              </p>

              <p></p>
            </div>
          </v-col>

          <!-- SIGNHERO -->
          <v-col cols="12" sm="6">
            <h3 class="mb-1">Signhero</h3>

            <h4 v-if="account.signHeroToken.token" class="success--text">Valtuutus tehty</h4>

            <p v-if="!account.signHeroToken.token">
              Valtuuta tilisi käyttämään Signhero-palvelua tililläsi.
            </p>
            <p v-else>Valtuuta tilisi uudelleen käyttämään Signhero-palvelua tililläsi.</p>

            <v-checkbox
              v-model="checkbox"
              :label="`Olen luonut tilin SignHero-palveluun.`"
            ></v-checkbox>
            <v-btn color="primary" @click="signContract">{{
              !account.signHeroToken.token ? "Luo valtuutus" : "Uudelleenvaltuuta"
            }}</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-text>
      <v-container> </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import mixins from "../../mixins/mixins";

export default {
  mixins: [mixins],

  data() {
    return {
      checkbox: false,
    };
  },

  computed: {
    ...mapState("settings", ["account"]),

    vismaSignInUse() {
      return this.account?.vismaSign?.organizationId ? true : false;
    },
  },

  methods: {
    ...mapMutations("settings", ["setFormField"]),

    async signContract() {
      if (this.checkbox) {
        let url =
          "https://signhero.io/oauth2/authorize?client_id=ff670955-2cd9-11eb-adac-4af235dee89b&redirect_uri=https://app.vuokranet.fi/settings&scope=basic+profile+signing_url&response_type=code&state=vuokranet";

        window.location = url;
      } else {
        this.showPopup("Hyväksy ensin palveluun kirjautumisehto.", "error");
      }
    },
  },
};
</script>

<style></style>
