<template>
  <v-card>
    <v-card-text>
      <v-container>
        <v-row dense align="center">
          <v-col cols="12" md="4" sm="5">
            <v-checkbox
              v-model="useDepositEmails"
              label="Käytä oletuksena vakuuden kuittausviestiä"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" md="4" sm="5">
            <v-checkbox
              v-model="depositTargetAmountNeeded"
              label="Pakollinen tavoitesumma"
            ></v-checkbox>
          </v-col>

          <v-col cols="12" sm="6" lg="5" xl="4">
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>Vakuuksien viestipohja</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <small
                    class="error--text"
                    v-html="
                      `{{body}} on viestin perusosa. Älä poista sitä. Voit käyttää myös tägiä {{user}}, jolloin se korvataan käyttäjän profiilitiedoilla. {{custom_message}} korvataan vakuuskohtaisella viestillä.`
                    "
                  >
                  </small>
                  <br />
                  <v-btn class="mt-1" small color="primary" @click="depositMsg = defaultDepositMsg"
                    >Aseta esimerkkiteksti</v-btn
                  >

                  <v-textarea ref="depositMsg" v-model="depositMsg"></v-textarea>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import mixins from "../../mixins/mixins";

export default {
  mixins: [mixins],

  data() {
    return {
      defaultDepositMsg: `<p>Hei,</p>\n<p>vakuus on vastaanotettu.</p>\n{{custom_message}}\n{{body}}\n<p class="regards">Ystävällisin terveisin,</p>\n{{user}}`,
    };
  },

  computed: {
    ...mapState("settings", ["account"]),

    useDepositEmails: {
      get() {
        return this.account.settings.useDepositEmails;
      },
      set(val) {
        this.setFormField({ field: "settings.useDepositEmails", val });
      },
    },
    depositTargetAmountNeeded: {
      get() {
        return this.account.settings.depositTargetAmountNeeded;
      },
      set(val) {
        this.setFormField({ field: "settings.depositTargetAmountNeeded", val });
      },
    },
    depositMsg: {
      get() {
        return this.account.settings.depositMsg;
      },
      set(val) {
        this.setFormField({ field: "settings.depositMsg", val });
      },
    },
  },

  methods: {
    ...mapMutations("settings", ["setFormField"]),
  },
};
</script>

<style></style>
