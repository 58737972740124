<template>
  <v-card>
    <v-card-text>
      <v-form ref="form">
        <v-container>
          <v-row dense>
            <v-col cols="12" md="4" sm="5" lg="3" xl="2">
              <v-text-field v-model="bank" label="Pankki" dense outlined></v-text-field>
            </v-col>
            <v-col cols="12" md="4" sm="5" lg="3" xl="2">
              <v-text-field v-model="iban" label="Tilinumero" dense outlined></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="5" lg="3" xl="2">
              <v-radio-group
                v-model="personalRefNumInUse"
                style="margin-top: 0px"
                label="Asiakaskohtaisen viitenumeron käyttö"
                :mandatory="true"
              >
                <v-radio
                  label="Aseta kaikille vuokralaisille"
                  :value="true"
                  @click.capture.stop="openConfirmDialog(true)"
                ></v-radio>
                <v-radio
                  label="Poista kaikilta vuokralaisilta"
                  :value="false"
                  @click.capture.stop="openConfirmDialog(false)"
                ></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12" md="4" sm="5" lg="3" xl="2">
              <label>Kustannuspaikkalaskenta</label>
              <v-checkbox
                v-model="useCondominiumCostAccounting"
                style="margin-top: 0px"
                label="Kiinteistökohtainen"
                hide-details
                @change="
                  useCondominiumCostAccounting
                    ? (useApartmentCostAccounting = false)
                    : useCondominiumCostAccounting
                "
              ></v-checkbox>
              <v-checkbox
                v-model="useApartmentCostAccounting"
                style="margin-top: 0px"
                label="Vuokrakohdekohtainen"
                hide-details
                @change="
                  useApartmentCostAccounting
                    ? (useCondominiumCostAccounting = false)
                    : useApartmentCostAccounting
                "
              ></v-checkbox>

              <!-- Dialog add costcentre -->
              <div v-if="showAddCostCentreToAll" class="mt-1">
                <h4>{{ costCentreToAllText }}</h4>
                <v-btn color="primary" small class="mt-1" @click="setCostCentreToAllInvoices"
                  >Aseta kustannuspaikat</v-btn
                >
              </div>
            </v-col>

            <v-col
              v-if="isAuthorized('invoice', 'invoiceStartNum')"
              cols="12"
              md="4"
              sm="5"
              lg="3"
              xl="2"
            >
              <v-text-field
                v-model.number="invoiceStartNum"
                v-only-numbers
                v-prevent-paste
                :rules="positiveNumberNotRequired"
                label="Ensimmäisen laskun numero"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" sm="6" lg="5" xl="4">
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>Laskun vakioteksti</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-textarea v-model="invoiceFreeText"></v-textarea>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>

          <div v-if="isAuthorized('outgoingPayment', 'create')">
            <h3 class="mt-3 mb-1">Lähtevät maksut</h3>
            <v-row dense>
              <v-col cols="12" md="4" sm="5" lg="3" xl="2">
                <v-checkbox
                  v-model="outgoingPayments"
                  style="margin-top: 0px"
                  label="Käytä lähtevien maksujen seurantaa"
                  hide-details
                ></v-checkbox>
              </v-col>

              <v-col v-show="outgoingPayments" cols="12" md="4" sm="5" lg="3" xl="2">
                <v-checkbox
                  v-model="subtractReceiptsFromOutgoing"
                  style="margin-top: 0px"
                  label="Lisää kulut lähteviin maksuihin"
                  hide-details
                ></v-checkbox>
              </v-col>

              <v-col v-show="outgoingPayments" cols="12" md="4" sm="5" lg="3" xl="2">
                <v-checkbox
                  v-model="createReceiptsPayments"
                  style="margin-top: 0px"
                  label="Luo kuluista oma maksurivi"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-form>
    </v-card-text>

    <!-- Loader for costcentre updates -->
    <download-loader
      v-model="showUpdateLoader"
      :loading="loading"
      text="Päivitetään laskuja"
      additionalText="Päivitys voi kestää hetken riippuen laskujen määrästä. Odota hetki..."
    ></download-loader>
  </v-card>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import mixins from "../../mixins/mixins";
import axiosMethods from "../../mixins/axios";
import DownloadLoader from "@/components/DownloadLoader.vue";

export default {
  mixins: [mixins],

  components: {
    DownloadLoader,
  },

  data(vm) {
    return {
      showUpdateLoader: false,
      loading: false,
      showAddCostCentreToAll: false,
      costCentreToAllText: "",
      emailRules: [(v) => /.+@.+\..+/.test(v) || "Email ei ole oikeassa muodossa"],
      required: [(v) => !!v || "Pakollinen kenttä"],
      positiveNumberNotRequired: [(v) => vm.testPositiveNumber(v) || "Anna positiivinen luku"],
    };
  },

  computed: {
    ...mapState("settings", ["account"]),
    ...mapState("account", ["currentUser"]),

    bank: {
      get() {
        return this.account.bank;
      },
      set(val) {
        this.setFormField({ field: "bank", val });
      },
    },
    iban: {
      get() {
        return this.account.iban;
      },
      set(val) {
        this.setFormField({ field: "iban", val });
      },
    },
    personalRefNumInUse: {
      get() {
        return this.account.settings.personalRefNumInUse;
      },
      set(val) {
        this.setFormField({ field: "settings.personalRefNumInUse", val });
      },
    },
    useCondominiumCostAccounting: {
      get() {
        return this.account.settings.useCondominiumCostAccounting;
      },
      set(val) {
        this.setFormField({ field: "settings.useCondominiumCostAccounting", val });
      },
    },
    useApartmentCostAccounting: {
      get() {
        return this.account.settings.useApartmentCostAccounting;
      },
      set(val) {
        this.setFormField({ field: "settings.useApartmentCostAccounting", val });
      },
    },
    invoiceStartNum: {
      get() {
        return this.account.settings.invoiceStartNum;
      },
      set(val) {
        this.setFormField({ field: "settings.invoiceStartNum", val });
      },
    },
    outgoingPayments: {
      get() {
        return this.account.settings.outgoingPayments;
      },
      set(val) {
        this.setFormField({ field: "settings.outgoingPayments", val });
      },
    },
    subtractReceiptsFromOutgoing: {
      get() {
        return this.account.settings.subtractReceiptsFromOutgoing;
      },
      set(val) {
        this.setFormField({ field: "settings.subtractReceiptsFromOutgoing", val });
      },
    },
    createReceiptsPayments: {
      get() {
        return this.account.settings.createReceiptsPayments;
      },
      set(val) {
        this.setFormField({ field: "settings.createReceiptsPayments", val });
      },
    },
    invoiceFreeText: {
      get() {
        return this.account.settings.invoiceFreeText;
      },
      set(val) {
        this.setFormField({ field: "settings.invoiceFreeText", val });
      },
    },
  },

  watch: {
    outgoingPayments(val) {
      if (!val) {
        this.setFormField({ field: "settings.subtractReceiptsFromOutgoing", val: false });
        this.setFormField({ field: "settings.createReceiptsPayments", val: false });
      }
    },

    useCondominiumCostAccounting(val) {
      if (val) {
        this.showAddCostCentreToAll = true;
        this.costCentreToAllText =
          "Aseta kiinteistökohtaiset kustannuspaikat kaikkiin luonnoslaskuihin ja aktiivisiin laskuihin";
      } else {
        if (!this.useApartmentCostAccounting) {
          this.showAddCostCentreToAll = false;
        }
      }
    },

    useApartmentCostAccounting(val) {
      if (val) {
        this.showAddCostCentreToAll = true;
        this.costCentreToAllText =
          "Aseta vuokrakohdekohtaiset kustannuspaikat kaikkiin luonnoslaskuihin ja aktiivisiin laskuihin";
      } else {
        if (!this.useCondominiumCostAccounting) {
          this.showAddCostCentreToAll = false;
        }
      }
    },
  },

  methods: {
    ...mapMutations("settings", ["setFormField"]),
    ...mapActions("account", ["getCurrentUser"]),
    ...mapActions("invoice", ["changeCostCentreToAllInvoices"]),

    async openConfirmDialog(value) {
      if (confirm("Haluatko varmasti muuttaa kaikkien vuokralaisten viitenumerolaskennan tapaa?")) {
        try {
          await this.changeRefNumToAll(value);
          await this.getCurrentUser();
        } catch (err) {
          this.showPopup(err, "error");
        }
      }
    },

    confirmCostAccountSettings() {
      if (
        !this.useCondominiumCostAccounting &&
        !this.useApartmentCostAccounting &&
        (this.currentUser.currentAccount.settings.useCondominiumCostAccounting !=
          this.useCondominiumCostAccounting ||
          this.currentUser.currentAccount.settings.useApartmentCostAccounting !=
            this.useApartmentCostAccounting)
      ) {
        if (
          confirm(
            "Kaikkien luonnoksien ja aktiivisten laskujen kustannuspaikat poistetaan käytöstä. Haluatko jatkaa?"
          )
        )
          return true;
        else return false;
      } else {
        return true;
      }
    },

    async changeRefNumToAll(val) {
      try {
        await axiosMethods.update("/api/v1/tenant/changeRefNumToAll", {
          value: val,
        });
        if (val) {
          this.showPopup(
            "Asiakaskohtainen viitenumeron käyttö asetettu päälle kaikille vuokralaisille ja tilin tiedot tallennettu.",
            "success"
          );
        } else {
          this.showPopup(
            "Asiakaskohtainen viitenumeron käyttö poistettu kaikilta vuokralaisilta ja tilin tiedot tallennettu.",
            "success"
          );
        }
      } catch (err) {
        throw new Error(err);
      }
    },

    async setCostCentreToAllInvoices() {
      if (
        confirm(
          "Haluatko varmasti asettaa kustannuspaikat kaikkiin luonnoslaskuihin ja aktiivisiin laskuihin?"
        )
      ) {
        try {
          this.loading = true;
          this.showUpdateLoader = true;
          await this.changeCostCentreToAllInvoices({
            useCondominiumCostAccounting: this.useCondominiumCostAccounting,
            useApartmentCostAccounting: this.useApartmentCostAccounting,
          });
          this.loading = false;
          this.showUpdateLoader = false;
          this.showPopup("Laskut päivitetty. Muista tallentaa asetukset!", "success");
        } catch (err) {
          this.loading = false;
          this.showUpdateLoader = false;
          this.showPopup(err.message, "error");
        }
      }
    },

    testPositiveNumber(val) {
      if (!val) {
        return true;
      }
      return val > 0 || "Anna positiviinen luku";
    },
  },
};
</script>

<style></style>
