<template>
  <v-card>
    <v-card-text>
      <v-container class="container-wrapper">
        <v-row dense>
          <v-col cols="12" md="4" sm="5">
            <v-checkbox
              v-model="setAsProspectAfterRevoke"
              label="Aseta vuokralaiset irtisanomisen jälkeen potentiaalisiksi vuokralaisiksi"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  computed: {
    ...mapState("settings", ["account"]),

    setAsProspectAfterRevoke: {
      get() {
        return this.account.settings.setAsProspectAfterRevoke;
      },
      set(val) {
        this.setFormField({ field: "settings.setAsProspectAfterRevoke", val });
      },
    },
  },

  methods: {
    ...mapMutations("settings", ["setFormField"]),
  },
};
</script>

<style></style>
