<template>
  <div>
    <div v-if="loading" class="loader-container">
      <v-progress-circular
        class="justify-center"
        :size="70"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>

    <v-card class="mt-1">
      <v-card-title class="error--text" v-if="hasAccountsConnected">
        Huom! Tämä on yhdistetty tili ja sen hallinta hoidetaan toisen tilin kautta.
      </v-card-title>
    </v-card>

    <v-form v-if="!loading && !hasAccountsConnected" ref="form">
      <v-card class="mt-1">
        <v-card-title>Perusasetukset</v-card-title>
        <v-card-text>
          <v-container>
            <h3 class="mb-1">Julkiset osoitteet</h3>
            <v-row dense class="mb-1">
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-if="!currentAccount.slugActive"
                  class="mt-1"
                  v-model="currentAccount.slug"
                  prefix="kohteet.vuokranet.fi/"
                  outlined
                  dense
                  :rules="required"
                ></v-text-field>

                <p v-if="currentAccount.slugActive" class="mb-2">
                  Julkinen osoiteesi on:
                  <strong class="success--text"
                    >https://kohteet.vuokranet.fi/{{ currentAccount.slug }}</strong
                  >
                </p>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-if="!currentAccount.websiteUrlActive"
                  class="mt-1"
                  v-model="currentAccount.settings.public.websiteUrl"
                  placeholder="https://vuokranet.fi/vuokrakohteet"
                  outlined
                  dense
                  label="Oman verkkosivun osoite"
                  :rules="startsWithHttp"
                ></v-text-field>

                <p v-if="currentAccount.websiteUrlActive" class="mb-2">
                  Oman verkkosivusi osoite:
                  <strong class="success--text">{{
                    currentAccount.settings.public.websiteUrl
                  }}</strong>
                </p>
              </v-col>
            </v-row>

            <v-divider class="mb-2"></v-divider>

            <v-row dense>
              <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <p>Toiminnallisuudet</p>
                <v-radio-group
                  v-model="currentAccount.settings.public.type"
                  :mandatory="true"
                  style="margin-top: 0px"
                  @change="currentAccount.settings.public.showFreeBeforeDays = false"
                >
                  <v-radio label="Kohde-esittelyt" :value="0"></v-radio>
                  <!-- <v-radio label="Varausjärjestelmä" :value="1"></v-radio> -->
                </v-radio-group>
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <p>Vuokrakohteiden esittäminen</p>
                <v-radio-group
                  v-model="currentAccount.settings.public.listStyle"
                  :mandatory="true"
                  style="margin-top: 0px"
                >
                  <v-radio label="Lista kuvilla" :value="0"></v-radio>
                  <v-radio label="Lista ilman kuvia" :value="1"></v-radio>
                  <v-radio label="Gridi kuvilla" :value="2"></v-radio>
                  <v-radio label="Gridi ilman kuvia" :value="3"></v-radio>
                </v-radio-group>
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <p>Vuokrakohteiden näkyvyys</p>
                <v-radio-group
                  v-model="currentAccount.settings.public.show"
                  :mandatory="true"
                  style="margin-top: 0px"
                >
                  <v-radio
                    v-if="currentAccount.settings.public.type == 0"
                    label="Näytä kaikki"
                    :value="0"
                  ></v-radio>
                  <v-radio label="Näytä vain vapaat" :value="1"></v-radio>
                  <v-radio
                    v-if="currentAccount.settings.public.type == 0"
                    label="Näytä vain valitut"
                    :value="2"
                  ></v-radio>
                  <v-radio label="Näytä vain vapaat valitut" :value="3"></v-radio>
                </v-radio-group>
              </v-col>

              <v-col
                v-if="
                  (currentAccount.settings.public.show == 1 ||
                    currentAccount.settings.public.show == 3) &&
                  currentAccount.settings.public.type != 1
                "
                cols="12"
                sm="6"
                md="3"
                lg="3"
                xl="2"
              >
                <p>Näytä myös x päivän päästä vapautuvat</p>
                <v-checkbox
                  v-model="currentAccount.settings.public.showFreeBeforeDays"
                  hide-details
                  style="padding: 0px; margin: 0px"
                ></v-checkbox>
                <div v-if="currentAccount.settings.public.showFreeBeforeDays">
                  <v-text-field
                    class="mt-1"
                    v-model.number="currentAccount.settings.public.freeBeforeDays"
                    v-only-numbers
                    v-prevent-paste
                    suffix="päivää"
                    outlined
                    dense
                    :rules="required.concat(positiveNumber)"
                  ></v-text-field>
                </div>
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <p>Näytä vain vuokrakohdetyypit</p>
                <v-select
                  v-model="currentAccount.settings.public.apartmentTypes"
                  :items="houseTypes"
                  outlined
                  small-chips
                  deletable-chips
                  multiple
                  hide-details
                ></v-select>
                <small>Jos yhtään ei ole valittu, näytetään kaikki.</small>
              </v-col>
            </v-row>
            <v-row dense align="end">
              <v-col cols="12" sm="6" md="3" lg="3" xl="2">
                <p>Näytä vuokrauskalenteri</p>

                <v-radio-group
                  v-model="currentAccount.settings.public.showReservationCalendar"
                  :mandatory="true"
                  style="margin-top: 0px"
                  hide-details
                >
                  <v-radio label="Kyllä" :value="true"></v-radio>
                  <v-radio label="Ei" :value="false"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col
                v-if="currentAccount.settings.public.showReservationCalendar"
                cols="12"
                sm="6"
                md="4"
                lg="3"
                xl="2"
              >
                <p>Näytä kalenterikuukausia kerralla</p>
                <v-text-field
                  dense
                  v-model.number="currentAccount.settings.public.showReservationCalendarMonths"
                  type="number"
                  min="1"
                  outlined
                  suffix="kk"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
                xl="2"
                v-if="currentAccount.settings.public.showReservationCalendar"
              >
                <p>Näytä kalenterin varaukset max päivän päähän</p>
                <v-text-field
                  v-model.number="currentAccount.settings.public.showReservationsBefore"
                  v-only-numbers
                  v-prevent-paste
                  outlined
                  dense
                  suffix="päivää"
                  :rules="required.concat(positiveNumber)"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>

      <!-- Rental settings -->
      <v-card class="mt-2">
        <v-card-title>Lomakeasetukset</v-card-title>

        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <p>Näytä "Vuokrakohteita ei löytynyt"-lomake, jos hakutuloksia ei löydy</p>
                <v-radio-group
                  v-model="currentAccount.settings.public.showEmptyResultsForm"
                  :mandatory="true"
                  style="margin-top: 0px"
                >
                  <v-radio label="Kyllä" :value="true"></v-radio>
                  <v-radio label="Ei" :value="false"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>

            <v-row v-if="currentAccount.settings.public.showEmptyResultsForm" dense>
              <v-col cols="12" sm="6" md="6" lg="5" xl="4">
                <p>"Vuokrakohteita ei löytynyt"-lomakkeen lisäkentät</p>
                <v-select
                  v-model="currentAccount.settings.public.emptyResultsFormFields"
                  :items="emptyResultsFields"
                  item-value="val"
                  item-text="text"
                  outlined
                  small-chips
                  deletable-chips
                  multiple
                ></v-select>
              </v-col>
            </v-row>

            <!-- Create fields -->
            <v-row
              v-if="
                currentAccount.settings.public.emptyResultsFormFields.length > 0 &&
                currentAccount.settings.public.showEmptyResultsForm
              "
              dense
            >
              <v-col
                v-if="currentAccount.settings.public.emptyResultsFormFields.includes('allocation')"
                cols="12"
                sm="6"
                md="4"
                xl="3"
              >
                <label>Select - tilanjakamisen valinnat</label>
                <v-textarea
                  v-model="currentAccount.settings.public.emptyResultsFormValues.allocation"
                  :placeholder="`Koko huoneisto \nJaettu tila`"
                  outlined
                  dense
                  hide-details
                ></v-textarea>
                <small>Luettele valinnat allekain ilman erotinmerkkejä</small>
              </v-col>
              <v-col
                v-if="currentAccount.settings.public.emptyResultsFormFields.includes('area')"
                cols="12"
                sm="6"
                md="4"
                xl="3"
              >
                <label>Select - koon valinnat</label>
                <v-textarea
                  v-model="currentAccount.settings.public.emptyResultsFormValues.area"
                  :placeholder="`Yksiöt \nKaksiot `"
                  outlined
                  dense
                  hide-details
                ></v-textarea>
                <small>Luettele valinnat allekain ilman erotinmerkkejä</small>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>

      <!-- Rental settings -->
      <v-card v-if="currentAccount.settings.public.type === 1" class="mt-2">
        <v-card-title>Vuokrausasetukset</v-card-title>
        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col cols="12" sm="6" md="6" lg="4" xl="3">
                <p>Varauksen ajanjakso</p>
                <v-select
                  v-model.number="currentAccount.settings.public.rentType"
                  :items="rentTypes"
                  item-value="val"
                  item-text="text"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col
                v-if="currentAccount.settings.public.rentType == 0"
                cols="12"
                sm="6"
                md="6"
                lg="5"
                xl="4"
              >
                <p>Valittavat vuokrausajat</p>
                <v-select
                  v-model="currentAccount.settings.public.fixedRentTimes"
                  :items="fixedRentTimes"
                  item-value="val"
                  item-text="text"
                  outlined
                  small-chips
                  deletable-chips
                  multiple
                  return-object
                ></v-select>
              </v-col>
              <v-col
                v-if="currentAccount.settings.public.rentType == 1"
                cols="12"
                sm="6"
                md="6"
                lg="3"
                xl="2"
              >
                <p>Min vuokra-aika</p>
                <v-text-field
                  dense
                  v-model.number="currentAccount.settings.public.minRentDays"
                  type="number"
                  min="1"
                  outlined
                  suffix="päivää"
                ></v-text-field>
              </v-col>
              <v-col
                v-if="currentAccount.settings.public.rentType == 1"
                cols="12"
                sm="6"
                md="6"
                lg="3"
                xl="2"
              >
                <p>Max vuokra-aika (tyhjä = ei määritelty)</p>
                <v-text-field
                  dense
                  v-model.number="currentAccount.settings.public.maxRentDays"
                  type="number"
                  min="1"
                  outlined
                  suffix="päivää"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- Global rent settings -->
            <v-row dense align="end">
              <v-col cols="12" sm="6" md="6" lg="3" xl="2">
                <p>Hinnan laskemisen oletustapa</p>
                <v-select
                  v-model="currentAccount.settings.public.priceCalcMethod"
                  :items="priceCalcMethods"
                  item-value="val"
                  item-text="text"
                  outlined
                  dense
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="3" xl="2">
                <p>Salli varauksen aloitus max päivän päähän</p>
                <v-text-field
                  v-model.number="currentAccount.settings.public.reserveBefore"
                  v-only-numbers
                  v-prevent-paste
                  outlined
                  dense
                  suffix="päivää"
                  hide-details
                  :rules="positiveNumberNotRequired"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>

      <!-- Search -->
      <v-card v-if="currentAccount.settings.public.type === 0" class="mt-2">
        <v-card-title>Hakuasetukset</v-card-title>
        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col cols="12" sm="6" md="6" lg="5" xl="4">
                <p>Näytä hakukentät</p>
                <v-select
                  v-model="currentAccount.settings.public.searchComponentItems"
                  :items="searchItems"
                  item-value="val"
                  item-text="text"
                  outlined
                  small-chips
                  deletable-chips
                  multiple
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="5" xl="4">
                <p>Talotyypin hakuarvot</p>
                <v-select
                  v-model="currentAccount.settings.public.houseTypeItems"
                  :items="houseTypes"
                  outlined
                  small-chips
                  deletable-chips
                  multiple
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>

      <!-- Apartment -->
      <v-card class="mt-2">
        <v-card-title>Vuokrakohteen näyttöasetukset</v-card-title>
        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col cols="12" sm="6" md="6" lg="5" xl="4">
                <p>Perustiedot</p>
                <v-select
                  v-model="currentAccount.settings.public.apartmentFields"
                  :items="apartmentFieldItems"
                  item-value="tag"
                  item-text="text"
                  outlined
                  small-chips
                  deletable-chips
                  multiple
                  return-object
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="5" xl="4">
                <p>Hintatiedot</p>
                <v-select
                  v-model="currentAccount.settings.public.rentFields"
                  :items="rentFieldItems"
                  item-value="tag"
                  item-text="text"
                  outlined
                  small-chips
                  deletable-chips
                  multiple
                  return-object
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="5" xl="4">
                <p>Muut kustannukset</p>
                <v-select
                  v-model="currentAccount.settings.public.priceFields"
                  :items="priceFieldItems"
                  item-value="tag"
                  item-text="text"
                  outlined
                  small-chips
                  deletable-chips
                  multiple
                  return-object
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="5" xl="4">
                <p>Taloyhtiötiedot</p>
                <v-select
                  v-model="currentAccount.settings.public.condominiumFields"
                  :items="condominiumFieldItems"
                  item-value="tag"
                  item-text="text"
                  outlined
                  small-chips
                  deletable-chips
                  multiple
                  return-object
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>

      <v-card class="mt-2">
        <v-card-title>Tyylit</v-card-title>
        <v-card-text>
          <v-container>
            <h3>Yleiset tyylit</h3>

            <v-row dense>
              <v-col cols="10" sm="5" md="4" lg="3" xl="2">
                <p>Fontti</p>
                <v-select
                  v-model="currentAccount.settings.public.font"
                  :items="fonts"
                  dense
                  outlined
                ></v-select>
              </v-col>

              <v-col cols="10" sm="5" md="4" lg="3" xl="2" class="shrink" style="max-width: 200px">
                <p>Pääväri</p>
                <v-color-picker
                  v-model="currentAccount.settings.public.primaryColor"
                  mode="hexa"
                  flat
                  :canvas-height="canvasHeight"
                  hide-mode-switch
                ></v-color-picker>
              </v-col>

              <v-col cols="10" sm="5" md="4" lg="3" xl="2" class="shrink" style="max-width: 200px">
                <p>Tehosteväri</p>
                <v-color-picker
                  v-model="currentAccount.settings.public.accentColor"
                  mode="hexa"
                  flat
                  :canvas-height="canvasHeight"
                  hide-mode-switch
                ></v-color-picker>
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="shrink" style="max-width: 200px">
                <p>Sivun taustaväri</p>
                <v-color-picker
                  v-model="currentAccount.settings.public.backgroundColor"
                  mode="hexa"
                  flat
                  :canvas-height="canvasHeight"
                  hide-mode-switch
                ></v-color-picker>
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="shrink" style="max-width: 200px">
                <p>Hyväksy napin väri</p>
                <v-color-picker
                  v-model="currentAccount.settings.public.actionBtnColor"
                  mode="hexa"
                  flat
                  :canvas-height="canvasHeight"
                  hide-mode-switch
                ></v-color-picker>
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="shrink" style="max-width: 200px">
                <p>Tyhjennä napin väri</p>
                <v-color-picker
                  v-model="currentAccount.settings.public.deleteBtnColor"
                  mode="hexa"
                  flat
                  :canvas-height="canvasHeight"
                  hide-mode-switch
                ></v-color-picker>
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="shrink" style="max-width: 200px">
                <p>Onnistui väri</p>
                <v-color-picker
                  v-model="currentAccount.settings.public.successColor"
                  mode="hexa"
                  flat
                  :canvas-height="canvasHeight"
                  hide-mode-switch
                ></v-color-picker>
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="shrink" style="max-width: 200px">
                <p>Error väri</p>
                <v-color-picker
                  v-model="currentAccount.settings.public.errorColor"
                  mode="hexa"
                  flat
                  :canvas-height="canvasHeight"
                  hide-mode-switch
                ></v-color-picker>
              </v-col>
            </v-row>

            <h3>Hakulomake</h3>

            <v-row dense>
              <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="shrink" style="max-width: 200px">
                <p>Hakutoiminnon taustaväri</p>
                <v-color-picker
                  v-model="currentAccount.settings.public.searchBackgroundColor"
                  mode="hexa"
                  flat
                  :canvas-height="canvasHeight"
                  hide-mode-switch
                ></v-color-picker>
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="shrink" style="max-width: 200px">
                <p>Hakutoiminnon reunaväri</p>
                <v-color-picker
                  v-model="currentAccount.settings.public.searchBorderColor"
                  mode="hexa"
                  flat
                  :canvas-height="canvasHeight"
                  hide-mode-switch
                ></v-color-picker>
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="shrink" style="max-width: 200px">
                <p>Lomakekentän otsikkoväri</p>
                <v-color-picker
                  v-model="currentAccount.settings.public.fieldLabelColor"
                  mode="hexa"
                  flat
                  :canvas-height="canvasHeight"
                  hide-mode-switch
                ></v-color-picker>
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="shrink" style="max-width: 200px">
                <p>Lomakekentän reunaväri</p>
                <v-color-picker
                  v-model="currentAccount.settings.public.fieldColor"
                  mode="hexa"
                  flat
                  :canvas-height="canvasHeight"
                  hide-mode-switch
                ></v-color-picker>
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="shrink" style="max-width: 200px">
                <p>Lomakekentän tekstin väri</p>
                <v-color-picker
                  v-model="currentAccount.settings.public.fieldTextColor"
                  mode="hexa"
                  flat
                  :canvas-height="canvasHeight"
                  hide-mode-switch
                ></v-color-picker>
              </v-col>
            </v-row>

            <h3>Vuokrakohdesivu</h3>

            <h4 class="mt-2">Header</h4>
            <v-row dense>
              <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="shrink" style="max-width: 200px">
                <p>Headerväri</p>
                <v-color-picker
                  v-model="currentAccount.settings.public.apartmentHeaderColor"
                  mode="hexa"
                  flat
                  :canvas-height="canvasHeight"
                  hide-mode-switch
                ></v-color-picker>
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="shrink" style="max-width: 200px">
                <p>Header tekstin väri</p>
                <v-color-picker
                  v-model="currentAccount.settings.public.apartmentHeaderTextColor"
                  mode="hexa"
                  flat
                  :canvas-height="canvasHeight"
                  hide-mode-switch
                ></v-color-picker>
              </v-col>
            </v-row>

            <h4 class="mt-2">Lomake</h4>
            <v-row dense>
              <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="shrink" style="max-width: 200px">
                <p>Lomakkeen taustaväri</p>
                <v-color-picker
                  v-model="currentAccount.settings.public.contactBackgroundColor"
                  mode="hexa"
                  flat
                  :canvas-height="canvasHeight"
                  hide-mode-switch
                ></v-color-picker>
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="shrink" style="max-width: 200px">
                <p>Lomakkeen reunaväri</p>
                <v-color-picker
                  v-model="currentAccount.settings.public.contactBorderColor"
                  mode="hexa"
                  flat
                  :canvas-height="canvasHeight"
                  hide-mode-switch
                ></v-color-picker>
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="shrink" style="max-width: 200px">
                <p>Lomakekentän otsikkoväri</p>
                <v-color-picker
                  v-model="currentAccount.settings.public.contactFieldLabelColor"
                  mode="hexa"
                  flat
                  :canvas-height="canvasHeight"
                  hide-mode-switch
                ></v-color-picker>
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="shrink" style="max-width: 200px">
                <p>Lomakekentän reunaväri</p>
                <v-color-picker
                  v-model="currentAccount.settings.public.contactFieldColor"
                  mode="hexa"
                  flat
                  :canvas-height="canvasHeight"
                  hide-mode-switch
                ></v-color-picker>
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="shrink" style="max-width: 200px">
                <p>Lomakekentän tekstin väri</p>

                <v-color-picker
                  v-model="currentAccount.settings.public.contactFieldTextColor"
                  mode="hexa"
                  flat
                  :canvas-height="canvasHeight"
                  hide-mode-switch
                ></v-color-picker>
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="shrink" style="max-width: 200px">
                <p>Varaustietojen taustaväri</p>
                <v-color-picker
                  v-model="currentAccount.settings.public.reservationBackgroundColor"
                  mode="hexa"
                  flat
                  :canvas-height="canvasHeight"
                  hide-mode-switch
                ></v-color-picker>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="shrink" style="max-width: 200px">
                <p>Varaustietojen tekstinväri</p>
                <v-color-picker
                  v-model="currentAccount.settings.public.reservationTextColor"
                  mode="hexa"
                  flat
                  :canvas-height="canvasHeight"
                  hide-mode-switch
                ></v-color-picker>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <v-btn color="info" class="mr-4 mt-5" @click="saveSettings"> Tallenna </v-btn>
    </v-form>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import globalValues from "../../configs/globalValues";
import mixins from "../../mixins/mixins";

export default {
  mixins: [mixins],

  data(vm) {
    return {
      loading: false,
      currentAccount: {
        slug: "",
        slugActive: false,
        websiteUrlActive: false,
        settings: {
          public: {
            websiteUrl: "",
            slug: "",
            type: 0,
            show: 0,
            showEmptyResultsForm: false,
            emptyResultsFormValues: { allocation: "", area: "" },
            showFreeBeforeDays: false,
            freeBeforeDays: 30,
            apartmentTypes: [],
            showReservationCalendar: false,
            showReservationCalendarMonths: 1,
            rentType: { text: "Kiinteä ajanjakso", val: 0 },
            fixedRentTimes: [],
            minRentDays: 1,
            maxRentDays: 365,
            listStyle: 0,
            primaryColor: "#0096C7",
            accentColor: "#0096C7",
            backgroundColor: "#0096C7",
            searchBackgroundColor: "#0096C7",
            searchBorderColor: "#0096C7",
            actionBtnColor: "#0096C7",
            deleteBtnColor: "#0096C7",
            fieldColor: "#0096C7",
            fieldTextColor: "#0096C7",
            fieldLabelColor: "#0096C7",
            contactFieldColor: "#0096C7",
            contactFieldTextColor: "#0096C7",
            contactFieldLabelColor: "#0096C7",
            contactBackgroundColor: "#0096C7",
            reservationBackgroundColor: "#0096C7",
            reservationTextColor: "#0096C7",
            contactBorderColor: "#0096C7",
            successColor: "#0096C7",
            errorColor: "#0096C7",
            apartmentHeaderColor: "#0096C7",
            apartmentHeaderTextColor: "#0096C7",
            font: "Bai Jamjuree",
            searchComponentItems: [],
            houseTypeItems: [],
            emptyResultsFormFields: [],
            apartmentFields: [],
            rentFields: [],
            priceFields: [],
            condominiumFields: [],
            priceCalcMethod: "month",
            reserveBefore: 30,
            showReservationsBefore: 30,
          },
        },
      },
      searchItems: [
        { text: "Talotyyppi", val: "houseType" },
        { text: "Huoneluku", val: "roomCount" },
        { text: "Vapaa sanahaku", val: "freeText" },
      ],
      emptyResultsFields: [
        { text: "Tilan jakaminen", val: "allocation" },
        { text: "Koko", val: "area" },
      ],
      priceCalcMethods: [{ text: "Kuukausihinta", val: "month" }],
      rentTypes: [
        { text: "Kiinteä ajanjakso", val: 0 },
        // { text: "Päiväkohtainen kalenterivalinta", val: 1 },
      ],
      houseTypes: globalValues.houseTypes,
      fixedRentTimes: [
        { text: "Toistaiseksi voimassa oleva", val: { months: 0, days: 0 } },
        { text: "1 viikko", val: { months: 0, days: 7 } },
        { text: "2 viikkoa", val: { months: 0, days: 14 } },
        { text: "3 viikkoa", val: { months: 0, days: 21 } },
        { text: "1 kuukausi", val: { months: 1, days: 0 } },
        { text: "2 kuukautta", val: { months: 2, days: 0 } },
        { text: "3 kuukautta", val: { months: 3, days: 0 } },
        { text: "4 kuukautta", val: { months: 4, days: 0 } },
        { text: "5 kuukautta", val: { months: 5, days: 0 } },
        { text: "6 kuukautta", val: { months: 6, days: 0 } },
        { text: "7 kuukautta", val: { months: 7, days: 0 } },
        { text: "8 kuukautta", val: { months: 8, days: 0 } },
        { text: "9 kuukautta", val: { months: 9, days: 0 } },
        { text: "10 kuukautta", val: { months: 10, days: 0 } },
        { text: "11 kuukautta", val: { months: 11, days: 0 } },
        { text: "1 vuosi", val: { months: 12, days: 0 } },
      ],
      apartmentFieldItems: [
        { text: "Vapautuu", tag: "apartmentRelease" },
        { text: "Vuokrataan kalustettuna", tag: "aparmentFurnished" },
        { text: "Sijainti", tag: "apartmentLocation" },
        { text: "Kaupunki", tag: "apartmentCity" },
        { text: "Vuokrakohteen tyyppi", tag: "apartmentType" },
        { text: "Kerros", tag: "apartmentFloor" },
        { text: "Pinta-ala", tag: "apartmentArea" },
        { text: "Kokoonpano", tag: "apartmentFloorPlan" },
        { text: "Kunto", tag: "apartmentCondition" },
        { text: "Parveke", tag: "apartmentBalcony" },
        { text: "Lemmikkieläimet", tag: "aparmentAnimals" },
        // { text: "Muut ehdot", tag: "apartmentTerms" },
      ],
      rentFieldItems: [
        { text: "Vuokra", tag: "rent" },
        { text: "Vakuus", tag: "deposit" },
        // { text: "Vuokra-aika", tag: "rentTime" },
      ],
      priceFieldItems: [
        { text: "Vesimaksu", tag: "water" },
        { text: "Sähkömaksu", tag: "electricity" },
      ],
      condominiumFieldItems: [
        { text: "Taloyhtiön nimi", tag: "condominiumName" },
        { text: "Rakennusvuosi", tag: "condominiumBuildYear" },
        { text: "Tontin pinta-ala", tag: "condominiumPlotArea" },
        { text: "Kerroksia", tag: "condominiumFloors" },
        { text: "Hissi", tag: "condominiumElevator" },
        { text: "Sauna", tag: "condominiumSauna" },
        { text: "Lämmitys", tag: "condominiumHeating" },
        { text: "Antennijärjestelmä", tag: "condominiumAntenna" },
        { text: "Energialuokka", tag: "condominiumEnergyClass" },
      ],
      fonts: globalValues.fonts,
      required: [(v) => !!v || "Pakollinen kenttä"],
      positiveNumber: [(v) => vm.checkPositiveNumber(v) || "Anna positiivinen luku"],
      startsWithHttp: [
        (v) =>
          v.startsWith("http://") || v.startsWith("https://") || "Pitää alkaa http:// tai https://",
      ],
      positiveNumberNotRequired: [(v) => vm.testPositiveNumber(v) || "Anna positiivinen luku"],
    };
  },

  async mounted() {
    this.loading = true;

    try {
      await this.getCurrentUser();
    } catch (err) {
      this.showPopup(err, "error");
    }
  },

  computed: {
    ...mapState("account", ["account", "currentUser"]),

    canvasHeight() {
      return "70";
    },

    hasAccountsConnected() {
      return this.currentUser?.currentAccount?.accountsConnected > 0;
    },
  },

  watch: {
    currentAccount: {
      deep: true,
      handler(val) {
        this.setAccount(val);
      },
    },

    currentUser(val) {
      this.currentAccount = { ...val.currentAccount };
      this.loading = false;
    },
  },

  methods: {
    ...mapMutations("account", ["setAccount"]),
    ...mapActions("account", ["savePublicSettings", "getCurrentUser"]),

    saveSettings() {
      if (this.$refs.form.validate()) {
        this.savePublicSettings(this.currentAccount);
      } else {
        this.showPopup("Tarkista pakolliset kentät", "error");
      }
    },

    checkPositiveNumber(v) {
      if (v > 0 || v == "") return true;
      else return false;
    },

    testPositiveNumber(val) {
      if (!val) {
        return true;
      }
      return val > 0 || "Anna positiviinen luku";
    },
  },
};
</script>

<style scoped>
.loader-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
