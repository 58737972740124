<template>
  <v-card>
    <v-container>
      <v-card-text>
        <v-form ref="form">
          <h3>{{ name }}</h3>
          <v-row dense>
            <v-col cols="12" sm="6" md="5" lg="4" xl="2">
              <v-radio-group v-model="isCompany" :mandatory="true" row hide-details>
                <v-radio label="Yksityinen" :value="false"></v-radio>
                <v-radio label="Yritys" :value="true"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" sm="6" md="5" lg="4" xl="2">
              <v-checkbox
                v-if="!isCompany"
                v-model="finnishCitizen"
                label="Minulla on suomalainen henkilötunnus"
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row dense class="mt-1">
            <v-col v-if="!isCompany" cols="12" md="4" sm="5" lg="4" xl="2">
              <v-text-field
                v-model.trim="social"
                label="Henkilötunnus"
                :rules="required"
                dense
                outlined
                hide-details
              ></v-text-field>
            </v-col>
            <v-col v-if="isCompany" cols="12" md="4" sm="5" lg="4" xl="2">
              <v-text-field
                v-model.trim="businessId"
                label="Y-tunnus"
                :rules="required"
                dense
                outlined
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" sm="5" lg="4" xl="2">
              <v-text-field
                v-model="phone"
                label="Puhelin"
                :rules="required"
                dense
                outlined
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" sm="5" lg="4" xl="2">
              <v-text-field
                v-model="address"
                label="Osoite"
                :rules="required"
                dense
                outlined
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" sm="5" lg="4" xl="2">
              <v-text-field
                v-model="zipCode"
                label="Postinumero"
                :rules="required"
                dense
                outlined
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" sm="5" lg="4" xl="2">
              <v-text-field
                v-model="city"
                label="Kaupunki"
                :rules="required"
                dense
                outlined
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" sm="5" lg="4" xl="2">
              <v-text-field
                v-model.trim="email"
                label="Email"
                :rules="required.concat(emailRules)"
                dense
                outlined
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>

        <v-row dense>
          <v-col cols="12" md="4" sm="5" lg="4" xl="2">
            <p>Logo</p>
            <image-upload
              v-if="!currentUser.currentAccount.images || !currentUser.currentAccount.images.length"
              bucket="vuokranet-logot"
              model="account"
              :multiple="false"
            ></image-upload>

            <!-- // LOGO -->
            <div
              style="display: flex"
              v-if="currentUser.currentAccount.images && currentUser.currentAccount.images.length"
            >
              <v-img
                :src="imageUrl"
                contain
                max-width="130px"
                max-height="100px"
                @error="reloadImage(currentUser.currentAccount.images[0])"
              >
              </v-img>
              <div>
                <v-icon
                  class="ml-1"
                  small
                  @click="deleteImage(currentUser.currentAccount.images[0])"
                  >mdi-delete</v-icon
                >
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import ImageUpload from "@/components/Image/UploadImages.vue";
import mixins from "../../mixins/mixins";

export default {
  mixins: [mixins],

  components: {
    ImageUpload,
  },

  data() {
    return {
      emailRules: [(v) => /.+@.+\..+/.test(v) || "Email ei ole oikeassa muodossa"],
      imageUrl: "",
      required: [(v) => !!v || "Pakollinen kenttä"],
    };
  },

  computed: {
    ...mapState("account", ["currentUser"]),
    ...mapState("settings", ["account"]),

    name() {
      return this.account.name;
    },

    isCompany: {
      get() {
        return this.account.isCompany;
      },
      set(val) {
        this.setFormField({ field: "isCompany", val });
      },
    },
    finnishCitizen: {
      get() {
        return this.account.finnishCitizen;
      },
      set(val) {
        this.setFormField({ field: "finnishCitizen", val });
      },
    },
    social: {
      get() {
        return this.account.social;
      },
      set(val) {
        this.setFormField({ field: "social", val });
      },
    },
    businessId: {
      get() {
        return this.account.businessId;
      },
      set(val) {
        this.setFormField({ field: "businessId", val });
      },
    },
    phone: {
      get() {
        return this.account.phone;
      },
      set(val) {
        this.setFormField({ field: "phone", val });
      },
    },
    address: {
      get() {
        return this.account.address;
      },
      set(val) {
        this.setFormField({ field: "address", val });
      },
    },
    zipCode: {
      get() {
        return this.account.zipCode;
      },
      set(val) {
        this.setFormField({ field: "zipCode", val });
      },
    },
    city: {
      get() {
        return this.account.city;
      },
      set(val) {
        this.setFormField({ field: "city", val });
      },
    },
    email: {
      get() {
        return this.account.email;
      },
      set(val) {
        this.setFormField({ field: "email", val });
      },
    },
  },

  watch: {
    "currentUser.currentAccount.images": {
      handler: "updateImageUrl",
      immediate: true,
    },

    social() {
      if (this.social) {
        this.setFormField({ field: "social", val: this.social.toUpperCase() });
        this.setFormField({ field: "businessId", val: "" });
      }
    },

    businessId() {
      if (this.businessId) {
        this.setFormField({ field: "social", val: "" });
      }
    },
  },

  methods: {
    ...mapMutations("settings", ["setFormField"]),
    ...mapActions("image", ["deleteLogo"]),

    updateImageUrl() {
      this.imageUrl = this.getLogoCloudFrontUrl(this.currentUser.currentAccount.images[0]);
    },

    reloadImage(img) {
      setTimeout(() => {
        const imageUrl = this.getLogoCloudFrontUrl(img);
        this.imageUrl = imageUrl;
      }, 1500);
    },

    validate() {
      if (!this.$refs.form.validate()) {
        this.showPopup("Tilin asetuksien pakolliset kentät ei ole täydennetty", "error");
      }

      return this.$refs.form.validate();
    },

    deleteImage(image) {
      const url = `/api/v1/image/delete-image/${this.currentUser.currentAccount._id}?key=${image.key}&bucket=vuokranet-logot&model=account`;
      this.deleteLogo(url);
    },
  },
};
</script>

<style></style>
